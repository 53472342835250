// import AOS from 'aos';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade , Thumbs } from 'swiper';
import AOS from 'aos';

Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);
window.Swiper = Swiper;

// JavaScript
$(document).ready( function () {
  AOS.init();

  // header
  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('#headerMenu').toggleClass('active');
    $('#header').toggleClass('active');
  });

  $('#headerProduct').click(function() {
    $(this).toggleClass('dropdown');
  });

  var header_type = $('.wrap').data('header');
  if (header_type == 'black') {
    $('.header').addClass('header-black');
  }
  
  $(document).click(function(event) {
    var menuLink = $('#headerProduct');
    var menuDropdown = $('.header-menu-dropdown');
    if (!$(event.target).closest(menuLink, menuDropdown).length) {
      menuLink.removeClass('dropdown');
    }
  });

  function headerScroll (){
    if ($(window).scrollTop() > 100) {
      $('.header').addClass("min-header");
    } else {
      $('.header').removeClass("min-header");
    }
  }
  headerScroll();
  $(window).on('scroll', function () {
    headerScroll();
  });

  // index slider
  var indexBanner = new Swiper('#indexBanner', {
    effect: 'fade',
    autoplay: {
      delay: 5000,
    },
    speed: 1000,
    loop: true,
    parallax: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  var indexProduct = new Swiper('#indexProduct', {
    grabCursor: true,
    slidesPerView: 1.3,
    spaceBetween: 10,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      800: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      900: {
        slidesPerView: 4,
        spaceBetween: 28,
      },
      2000: {
        slidesPerView: 5,
        spaceBetween: 35,
      },
    },
  });

  //scroll top
  $('#backTop').click(function(){
    $("html,body").animate({scrollTop: 0}, 600);
  });

  // tab
  $(function(){
    var tab = $('.tabs-group .tabs-btn a.tab');
    $(tab.eq(0).addClass('active').attr('href')).addClass('active').siblings('.tab-content').hide();

    tab.click(function(){
        event.preventDefault();
        $($(this).attr('href')).addClass('active').slideDown().siblings('.tab-content').hide();
        $(this).addClass('active').siblings('.active').removeClass('active');
    });
  });
});
